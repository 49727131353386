@import "../../../scss/variables";

.export-button {
  &__container {
    display: inline-block;
  }

  &__label {
    font-weight: 400;
    letter-spacing: 0;
  }

  &__icon {
    padding: 0;
    border: none;
  }

  &__dropdown-container {
    background: map-get($greyPalette, grey1);
    border: none;
    min-width: 136px;
    padding: 4px 0;
  }

  &__link {
    padding: 0;

    &:hover {
      background: map-get($greyPalette, grey2);
    }

    a {
      width: 100%;
      display: block;
      color: inherit;
      padding: 6px 8px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}