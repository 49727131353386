@import "../../scss/variables";
@import "../../scss/mixins.scss";

%sign-form-row {
  height: 100vh;
  background-color: map-get($greyPalette, grey1);
  display: flex;
  justify-content: center;
  align-items: center;
}

%sign-form-footer {
  width: 100%;
  padding: 1rem;
  padding-top: 0;
  background-color: white;
}

%sign-form-body {
  background-color: map-get($greyPalette, white);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

%sign-form-button {
  font-weight: 600;
  font-style: normal;
  line-height: 16px;
}

%sign-form-reset-link {
  font-size: 12px;
}

%sign-form-privacy-text {
  padding-top: 18px;
  font-size: 10px;
  line-height: 14px;
  text-align: right;
  color: map-get($greyPalette, grey9);
}

.success-alert {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
}

.sign-form {
  //non-desktop
  &-non-desktop {
    &__row {
      @extend %sign-form-row;
    }

    &__form {
      min-width: auto;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: space-between;
      background-color: white;
      flex-direction: column;
    }

    &__footer {
      @extend %sign-form-footer;
    }

    &__logo {
      position: absolute;
      top: 0.7rem;
      left: 1rem;
    }

    &__header {
      position: relative;
      background-color: white;
      color: #202022;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.08em;
      text-align: center;
    }
  
    &__body {
     @extend %sign-form-body;
    }
  
    &__button {
      @extend %sign-form-button;
    }
  
    &__reset-link {
      @extend %sign-form-reset-link;
    }
  
    &__privacy-text {
      @extend %sign-form-privacy-text;
    }
  }

  //desktop
  &__row {
    @extend %sign-form-row;
  }

  &__form {
    min-width: 500px;
    max-width: 550px;
    margin: 0 auto;
    border-radius: 4px;
  }

  &__footer {
    @extend %sign-form-footer;
  }
  
  &__header {
    background-color: map-get($purplePrimaryPalette, purplePrimary);
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__body {
    @extend %sign-form-body;
  }

  &__button {
    @extend %sign-form-button;
  }

  &__reset-link {
    @extend %sign-form-reset-link;
  }

  &__privacy-text {
    @extend %sign-form-privacy-text;
  }
}
