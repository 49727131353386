@import '../../scss/variables.scss';

/* Tooltip container */
.tooltip {

  &__container {
    position: relative;

    /* Show the tooltip text when you mouse over the tooltip container */
    &:hover .tooltip__text {
      visibility: visible;
      opacity: 1;
    }
  }

  &__text {
    visibility: hidden;
    width: 240px;
    background-color: map-get($purplePrimaryPalette, purplePrimary);
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    white-space: pre-line;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    left: 100%;
    top: 0;
    margin-top: -1px;
    margin-left: 10px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    /* Tooltip arrow */
    &::after {
      content: "";
      position: absolute;
      top: 15px;
      left: 0%;
      margin-left: -10px;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent map-get($purplePrimaryPalette, purplePrimary) transparent transparent;
    }
  }
}