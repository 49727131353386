@import "../../../scss/variables";
@import "../../../scss/mixins.scss";

%spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 67px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

%results-container {
  display: flex;
  align-items: center;
  margin: auto;
}

.spinner {
  //non-desktop
  &-non-desktop {
    &__container {
      @extend %spinner-container;
      background-color: white;
    }
  }
  //desktop
  &__container {
    @extend %spinner-container;
    background-color: map-get($greyPalette, grey1);

    &_flex-column {
      flex-direction: column;
    }

    &_pop-up {
      flex-direction: column;
      background-color: #fff;
      opacity: 0.8;
      z-index: 2;
    }
  }

  &__label {
    color: map-get($purplePrimaryPalette, purplePrimary);
    size: 12px;
  }
}

.results {
  //non-desktop
  &-non-desktop {
    &__container {
      @extend %results-container;
      width: 97%;
      justify-content: space-between;
    }

    &__recognize-button {
      position: fixed;
      bottom: 10px;
      left: calc(50% - 62.5px);
      z-index: 1;
    }

    &__pagination {
      padding-left: 1.5%;
      padding-right: 1.5%;
      position: fixed;
      top: 67px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      padding: 0;
      background-color: white;
  
      .pagination {
        padding-left: 7.5px; 
      }
    }
  }

  //desktop
  &__container {
    @extend %results-container;
    width: 80%;
    justify-content: space-between;
  }
  
  &__pagination {
      background-color: map-get($greyPalette, grey1);
      padding-left: 10%;
      padding-right: 10%;

      .pagination {
        padding-left: 7.5px; 
      }
    }

  
 
}