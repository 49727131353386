@import "../../scss/variables.scss";

.usepagination {
  //change reactbootstrap styles
  .pagination-sm {
    nav {
      background-color: map-get($greyPalette, grey1);
    }

    li {
      &:first-child {
        margin-right: 4px;
      }
      &:last-child {
        margin-left: 4px;
      }
    }

    .page-link {
      background-color: map-get($greyPalette, grey1);
      user-select: none;
      box-shadow: none;
      font-family: "Segoe UI";
      font-weight: 600;
      letter-spacing: 1px;
      margin-left: 0;
    }
  }

  &__arrow {
    width: 31px;
    svg {
      margin-bottom: 2.5px;
    }
  }
  &__number {
    margin: 0 !important;
    .page-link {
      width: 31px;
      color: map-get($greyPalette, grey5);
    }
    button {
      border-radius: 0 !important;
    }

    &_active {
      &.page-link {
        background-color: white;
        border-color: map-get($purplePrimaryPalette, purplePrimary);
        color: map-get($purplePrimaryPalette, purplePrimary);
      }
    }
    &_not-active {
      &.page-link {
        color: map-get($greyPalette, grey5);
      }
    }
  }

  &__egde {
    border-radius: 2px !important;
    &.page-link {
      color: map-get($purplePrimaryPalette, purplePrimary);
    }
  }
}
