@import "../../scss/variables.scss";


.recognition-text-area {

  &__field {
    position: relative;
    background-color: #FFF;
    overflow: auto;

    textarea:disabled {
      color: black;
    }

    textarea:focus {
      outline: 1px solid map-get($purplePrimaryPalette, purplePrimary);
    }
  }

  &__result-area {
    border-bottom: 1px solid white;
    overflow: auto;


    &:hover {
      background-color: map-get($purplePrimaryPalette, purpleHover);
      border-bottom: 1px solid map-get($purplePrimaryPalette, purplePrimary);
    }
  }

  &__bun {
    &_selected {
      border-bottom: 1px solid white !important;
      background-color: white !important;
    }
  }


  
}

.result-area {
  &__paragraph {
    color: #929292;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 0px ;
  }
}
