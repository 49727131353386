@import "../../scss/variables.scss";

.module-view {
  background-color: map-get($greyPalette, grey1);
  position: relative;
  min-height: 434px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.template-preview {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  left: 60%;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 50px;
}
