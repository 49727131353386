@import "../../scss/variables";
@import "../../scss/mixins.scss";


.templates {
  &__button {
    position: fixed;
    left: calc(50% - 71px);
    bottom: 18px;
  }
  &__pagination {
    background-color: map-get($greyPalette, grey1);
    padding-left: 10%;
    padding-right: 10%;
    overflow: auto;
  }
}

.spinner {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($greyPalette, grey1);
    @include use-media-query(non-desktop) {
      background-color: white;
    }
  }
 
}

.no-data {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 108px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($greyPalette, grey1);
  }
}
