@import '../../scss/variables';
@import '../../scss/mixins.scss';

%main-row {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 67px;
}

%main-row-buttons-container {
  display: flex;
  flex: 1 1 auto;
  align-items: flex-end;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

%main-row-left-column {
  position: relative;
  padding-right: 0;
  background-color: map-get($greyPalette, grey1);
}

%main-row-right-column {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: map-get($greyPalette, grey3);
}

%left-column-upper-div {
  display: flex;
  position: relative;
  justify-content: space-between;
}

%left-column-group-pop-up {
  color: map-get($greyPalette, grey6);
}

%left-column-group-pop-up-active {
  color: map-get($purplePrimaryPalette, purplePrimary);
}

.wrapper {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.spinner {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($greyPalette, grey1);
  }
}

.main-row {
  //nondesktop
  &-non-desktop {
    @extend %main-row;
    flex-wrap: wrap;
    overflow: auto;

    &__save-button {
      width: 50%;
      .btn {
        width: 100%;
      }
    }
    &__cancel-all-actions-button {
      width: 50%;
    }

    &__buttons-container {
      @extend %main-row-buttons-container;
      justify-content: space-between;
    }

    &__left-column {
      @extend %main-row-left-column;
      height: 50%;
      order: 1;
      flex: 0 1 auto;
      min-height: 260.5px;
    }

    &__right-column {
      @extend %main-row-right-column;
      height: 50%;
      order: 0;
      flex: 0 1 auto;
      min-height: 260.5px;
    }
  }

  //desktop
  @extend %main-row;
  flex-wrap: nowrap;
  
  &__buttons-container {
    @extend %main-row-buttons-container;
    justify-content: flex-end;
  }

  &__left-column {
    @extend %main-row-left-column;
    height: 100%;
    order: 0;
    flex-basis: 400px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__right-column {
    @extend %main-row-right-column;
    height: 100%;
    order: 1;
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.left-column {
  //nondesktop
  &-non-desktop {
    &__upper-div {
      @extend %left-column-upper-div;
      background-color: #FFF;
      border: none;
      padding: 0.5rem; 
    }

    &__group-pop-up {
      font-size: 14px;
      @extend %left-column-group-pop-up;
  
      &_active {
        @extend %left-column-group-pop-up-active;
      }
    }
  } 
  
  //desktop
  &__upper-div {
    @extend %left-column-upper-div;
    border-top: 1px solid map-get($greyPalette, grey3);
    border-bottom: 1px solid map-get($greyPalette, grey3);
    background-color: #FFF;
    padding: 1rem;
  }

  &__template-name {
    font-size: 18px;
    font-weight: bold;
  }

  &__file-name {
    color: map-get($greyPalette, grey5);
    font-size: 12px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__group-pop-up {
    font-size: 12px;
    @extend %left-column-group-pop-up;

    &_active {
      @extend %left-column-group-pop-up-active;
    }
  }

  &__spinner-container {
    text-align: center;
    background-color: #FFF;
  }
}

.spinner-container {
  &__label {
    color: map-get($purplePrimaryPalette, purplePrimary);
    size: 12px;
  }
}

.upper-div {
  &__label {
    font-size: 18px;
    font-weight: bold;
  }
}

.recognition-manage-actions-panel {
  z-index: 99;
  position: absolute;
  top: 12px;
  right: 10px;
}
.ref-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 58px;
}

