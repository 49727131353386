@import "src/scss/variables";

.help-page {
  font-size: 18px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      color: map-get($greyPalette, grey7);

      span {
        flex-shrink: 10;
      }

      svg {
        margin-right: 19px;
      }
    }
  }

  &__title {
    color: map-get($greyPalette, grey10);
    font-size: 28px;
    line-height: 36px;
    padding-bottom: 24px;
  }

  &__paragraph {
    padding-bottom: 25px;
  }

  &__bold {
    font-weight: bold;
  }

  &__subtitle {
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__list-title {
    margin-bottom: 1rem;
  }

  &__image-container {
    display: flex;
    justify-content: center;
  }

  &__image {
    margin-top: 40px;

    &-4 {
      margin-top: 25px;
    }

    &-6 {
      margin-top: 38.5px;
    }

    &-7 {
      margin-top: 47px;
    }

    &-9 {
      margin-top: 64px;
    }
  }

  &__list-title {
    margin-top: 1rem;
  }

  p {
    color: map-get($greyPalette, grey7);
    margin-bottom: 0;
  }
}