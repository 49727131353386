@import "../../scss/variables";

.user-dropdown {
  display: flex;
  align-items: center;
  height: 35px;

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    line-height: 35px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    border: none;
    background-color: #75cdff;
    color: #646870;
    cursor: pointer;
  }

  &__container {
    padding: 0;
    margin: 0;
    border: none;
    height: 100%;
    display: flex;
    align-items: center;

    &:active {
      //Override bootstrap style
      background: none !important;
    }
  }

  &__icon {
    cursor: pointer;
    transition: transform 0.3s;

    &_open {
      transform: rotate(180deg);
    }
  }
}

.list {
  //Override bootstrap style
  border: none !important;

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__item {
    color: map-get($greyPalette, grey6);
    font-size: 14px;

    &:focus {
      outline: none;
    }

    &:active {
      //Override bootstrap style
      background: #f8f9fa !important;
      color: map-get($greyPalette, grey6) !important;
    }
  }
}

.header {
  &__username {
    font-weight: bold;
    margin-bottom: 6px;
  }
}
