.support-mobile-version {
    background-color: white;
    position: fixed;
    top: 67px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    padding-bottom: 5.5rem;
    overflow: auto;

    &__description {
        text-align: center;
        padding: 2.5rem;
        font-family: 'Segoe UI';
        font-size: 16px;
        line-height: 30px;
    }

    &__description-logo {
        margin-bottom: 2rem;
    }

    &__donation_field {
        margin-top: 1rem;
    }

    &__tip {
        font-family: 'Segoe UI';
        font-size: 16px;
        line-height: 22px;
        color: #202022;
        opacity: 0.6;
    }

    &__input-symbol {
        position: relative;
        
        display: inline-block;
        width: 100%;
    
        input {
          padding-left: 18px;
        }
    
        &:before {
          position: absolute;
          top: 1px;
          color: gray;
          content: "$";
          left: 5px;
        }
      }

    &__buttons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 40px;
        // padding: 1rem;
    }

    &__cancel-button {
        text-transform: uppercase;
        width: 49%;
        height: 100%;
      }
    
      &__ok-button {
        width: 100%;
        height: 100%;
        text-transform: uppercase;
      }

      &__paypal-button {
          width: 49%;
          height: 100%;
      }
}