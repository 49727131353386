@import "../../scss/variables.scss";

.category-panel {
  background-color: map-get($greyPalette, grey1);
  position: relative;
  button {
    outline: none;
  }

  &__upper-part {
    background-color: map-get($greyPalette, grey2);
  }

  &__scroll-menu {
    white-space: nowrap;
    overflow: auto;
    width: calc(100% - 140px);
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      /* Hide scrollbar for Chrome, Safari and Opera */
      display: none;
    }
    p {
      display: inline-block;
    }
  }

  &__buttons-field {
    button {
      border: none;
      width: 20px;
      padding: 0;
      background-color: map-get($greyPalette, grey2);
    }
  }

  &__toogle-view-component {
    margin-top: 3px;
  }

  &__drop-menu {
    position: absolute;
    top: 100%;
    z-index: 99;
    background-color: map-get($greyPalette, grey2);
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0px 4px 14px rgba(100, 105, 112, 0.3);
    border-radius: 4px;
    width: calc(100% - 70px);
    p {
      padding: 0.5rem;
    }
  }
}

.category-name {
  font-size: 12px;
  font-weight: bold;
  color: map-get($greyPalette, grey5);

  &.predefined {
    font-size: 14px;
  }

  &:hover {
    color: map-get($purplePrimaryPalette, purplePrimary) !important;
  }

  &_selected {
    color: map-get($purplePrimaryPalette, purplePrimary);
  }
}

.view-list-icon {
  width: 16px;
  height: 16px;
  fill: map-get($greyPalette, grey5);

  &_selected {
    fill: map-get($purplePrimaryPalette, purplePrimary);
  }
}
