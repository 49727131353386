.warning-modal {
  &__header {
    color: white;
  }

  .modal-content {
    border: none;
    width: auto;
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}