@import "src/scss/variables";


.instructions-list-mobile-version {

    &__wrapper {
        background-color: white;
        position: fixed;
        top: 67px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
    }
    
    &__page-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    &__page-list-item {
        padding: 16px 36px;
        border-bottom: 1px solid map-get($greyPalette, grey2);
        background-color: map-get($greyPalette, grey1); 
    }

}
