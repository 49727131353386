.help-modal {
  &__header {
    //rewrite bootstrap styles
    .modal-title {
      color: white;
    }
    .close {
      color: white;
      opacity: 1;
      font-weight: 400;
      font-size: 28px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    span {
      color: #404040;
    }
  }

  &__instruction {
    padding-left: 40px;
    padding-top: 5px;
    color: #404040;
  }

  //rewrite bootstrap styles
  .modal-content {
    border: none;
  }

  //rewrite bootstrap styles
  .modal-body {
    padding: 2rem !important;
  }
}
