@import "../../scss/variables.scss";

.header-navbar {
  position: relative;
  display: flex;
  justify-content: space-between;

  &__container {
    display: flex;
    align-items: center;
  }

  &__disabled-item {
    color: map-get($greyPalette, grey5) !important;
  }
}

.user-info-container {
  text-align: center;

  &__info {
    display: block;
    margin-top: -3px;
    text-align: center;
  }
}
