@import "../../scss/variables.scss";
@import "../../scss/mixins.scss";

%new-instance-row {
  position: fixed;
  top: 67px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: map-get($greyPalette, grey1);
  display: flex;
  align-items: center;
  justify-content: center;
}

%new-instance-row-pop-up {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 4;
}

%new-instance-row-choose-file-button {
  background-color: map-get($purplePrimaryPalette, purplePrimary);
  color: white;
  border: 1px solid map-get($purplePrimaryPalette, purplePrimary);
  font-weight: 600;
}

.new-instance-row {
  //non desktop
  &-non-desktop {
    @extend %new-instance-row;
    padding-bottom: 6.25rem;
    background-color: white;
    &__warning {
      position: fixed;
      left: 1rem;
      right: 1rem;
      bottom: 4rem;
      color: #848484;
    }
    &__pop-up {
      @extend %new-instance-row-pop-up;
    }

    &__choose-file-button {
      @extend %new-instance-row-choose-file-button;
      position: fixed;
      left: 1rem;
      right: 1rem;
      bottom: 1rem;
      text-align: center;
    }
  }
  
  //desktop
  @extend %new-instance-row;

  &__pop-up {
    @extend %new-instance-row-pop-up;
  }

  &__choose-file-button {
    @extend %new-instance-row-choose-file-button;
  }
}

.dropzone {
  position: fixed;
  top: 58px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  outline: none;
  border: none;
  z-index: 2; //need to drag and drop working

  &_active {
    top: 88px;
    border: 3px dashed map-get($purplePrimaryPalette, purplePrimary);
    border-radius: 12px;
    background-color: rgba(86, 116, 236, 0.1);
  }
}

.black-text {
  color: map-get($greyPalette, typeBlack);
}

.grey-text {
  color: map-get($greyPalette, grey6);
}

.bold-text {
  font-weight: bold;
}

.close-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  fill: map-get($greyPalette, grey6);
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
}
