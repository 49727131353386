@import "variables";

.modal-header {
  background-color: map-get($purplePrimaryPalette, purplePrimary);
  padding: 0.75rem 1rem;
}

.modal-title {
  font-weight: 600;
  color: white;
}

.close {
  color: white;
  opacity: 1;
  text-shadow: none;
  font-weight: 500;
  font-size: 1.75rem;
}
