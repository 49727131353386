@import '../../../scss/variables';

.contact-modal {
  color: map-get($greyPalette, typeBlack);

  &__header {
    //Override bootstrap styles
    background: #FFF;
    border-bottom: none;
    padding: 8px 8px 4px 50px;

    h5 {
      font-weight: 700;
    }
  }

  &__body {
    padding: 4px 50px 25px 50px;
  }
}