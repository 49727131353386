.document-card {
  padding: 1rem;
  //   background-color: #e6e8ed;
  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: rgba(60, 60, 67, 0.6);
      font-size: 12px;
      line-height: 16px;
    }

    span:first-child {
      margin-right: 10px;
    }
  }

  &__name {
    padding-top: 0.75rem;
    font-size: 14px;
    line-height: 20px;
  }
}
