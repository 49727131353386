@import "../../scss/variables";
@import "../../scss/mixins.scss";

%file-anchor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

%file-title-container {
  background-color: map-get($greyPalette, grey2);
  width: 240px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.file-anchor {
  //non-desktop
  &-non-desktop {
    @extend %file-anchor;
    width: 90%;
    button:first-child {
      margin-bottom: 7.5px;
    }

    &__buttons-wrapper {
      position: fixed;
      left: 1rem;
      right: 1rem;
      bottom: 1rem;
      text-align: center;
      z-index: 2;
    } 
  }

  //desktop
  @extend %file-anchor;
}

.file-title {
  //non-desktop
  &-non-desktop {
    &__container {
      @extend %file-title-container;
      width: 100%;
      margin: 0 i !important;
    } 
  }

  //desktop
  &__container {
    @extend %file-title-container;
  }

  &__title {
    margin: 0;
    padding-bottom: 2px;
    font-size: 14px;
    flex: 1 1 auto;
  }

  &__close-icon {
    margin-right: 8px;
  }
}

.close-icon {
  &_small {
    z-index: 2;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}

.recognize-usin-template-button-wrapper, .done-button-wrapper {
  z-index: 2;
}

.done-button-wrapper {
  .btn {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}

.cancel-button {
  z-index: 2;
  color: map-get($greyPalette, grey5);
  border-color: map-get($greyPalette, grey5);
  background-color: white;
}
