@import "../../scss/variables.scss";

.custom-dropdown {
  &__list-group {
    position: absolute;
    top: 100%;
    margin-top: 2.5px;
    right: -2px;
    box-shadow: 0 4px 14px rgba(100, 105, 112, 0.3);
    border-radius: 2px;
    z-index: 99;
    min-width: 260px;
  }

  &__error-field {
    color: red;
    font-size: 14px;
  }

  &__list-group-item {
    cursor: pointer;
    color: #000;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 39px;

    &:hover {
      background-color: map-get($purplePrimaryPalette, purpleHover);

      input {
        background-color: map-get($purplePrimaryPalette, purpleHover);
      }

      input:hover {
        cursor: pointer;
      }
    }

    &_blocked {
      cursor: not-allowed;

      &:hover {
        background-color: #fff;
      }

      input {
        background-color: #fff;
      }
    }
  }

  &__input {
    border: none;
    outline: none;
  }

  &__button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__no-groups-placeholder {
    background-color: #fff;
    color: map-get($greyPalette, grey5);
    text-align: center;
    white-space: nowrap;
    font-size: 14px;
    padding: 22px 97px;
  }
}

.pencil-icon {
  width: 12px;
  height: 12px;
  fill: map-get($greyPalette, grey6);
}

.close-icon {
  width: 10px;
  height: 10px;
  fill: map-get($greyPalette, grey6);
}

.plus-icon {
  cursor: pointer;
}

.add-new-group-wrapper {
  border: 1px solid map-get($purplePrimaryPalette, purplePrimary);
  outline: none;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  &__input {
    border: none;
    border-right: 1px solid map-get($purplePrimaryPalette, purplePrimary);
    outline: none;
    font-size: 14px;
    flex-grow: 1;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 34px;
  }
}

.new-field-container {
  justify-content: center;

  &__field {
    color: map-get($purplePrimaryPalette, purplePrimary);
    font-size: 12px !important;

    &_blocked {
      color: map-get($greyPalette, grey5);
    }
  }
}
