@import "variables";

.navbar {
  // padding-right: 0;
  .nav-link {
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    margin: 0 1rem;
    color: map-get($greyPalette, typeBlack);

    &:not(:disabled):hover {
      color: map-get($purplePrimaryPalette, purplePrimary);
    }

    &:disabled {
      color: map-get($greyPalette, grey4);
    }
  }

  .active {
    padding-bottom: 18px;
    margin-bottom: -18px;
    border-bottom: 3px solid map-get($purplePrimaryPalette, purplePrimary);
    color: map-get($greyPalette, typeBlack);
  }
}
