@import "../../../scss/variables.scss";

.manage-actions-panel {
  &__left-button {
    padding: 0;
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 2px 0px 0px 2px;
    background-color: map-get($greyPalette, grey1);
    &:hover:not(.disabled) {
      background-color: white !important;
    }
    &:disabled {
      background-color: map-get($greyPalette, grey2);
    }
  }
  &__right-button {
    padding: 0;
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 0px 2px 2px 0px;
    background-color: map-get($greyPalette, grey1);
    &:hover:not(.disabled) {
      background-color: white !important;
    }
    &:disabled {
      background-color: map-get($greyPalette, grey2);
    }
  }
}
