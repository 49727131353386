@import "../../../scss/variables";

.edit-box {
  &__container {
    height: 100%;
  }

  &__input {
    width: 100%;
    position: relative;
  }

  &__button-field {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: map-get($greyPalette, grey1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
}