@import "../../../scss/variables";

.control-panel {
  &__select-button {
    border-radius: 5px 5px 0 0;
  }

  &__edit-button, &__resize-button {
    border-radius: 0;
  }

  &__edit-button {
    border-top-color: map-get($greyPalette, grey2) !important;
    border-bottom-color: map-get($greyPalette, grey2) !important;
  }

  &__resize-button {
    border-bottom-color: map-get($greyPalette, grey2) !important;
  }

  &__move-button {
    border-radius: 0 0 5px 5px;
  }
}