.pop-up-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 42px;
  background-color: #fff;
  z-index: 2;
}
