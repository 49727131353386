@import "../../scss/variables.scss";

.modal {
  &__header {
    line-height: 1.5;
  }

  &__body {
    display: flex;
    justify-content: center;
  }

  &__footer {
    border-top: none;
  }
}