@import "../../../scss/variables.scss";

.drop-menu-component {
  &__category-name {
    font-size: 12px;
    font-weight: bold;
    color: map-get($greyPalette, grey5);

    &:hover {
      color: map-get($purplePrimaryPalette, purplePrimary) !important;
    }

    &_selected {
      color: map-get($purplePrimaryPalette, purplePrimary);
    }
  }
}
