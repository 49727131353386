@import "../../../../scss/variables";

.marked-area-list {
  &__label {
    color: map-get($greyPalette, grey5);
    font-size: 12px;
  }

  &__list-container {
    textarea {
      border: 0;
      outline: 0;
      background-color: transparent;
      resize: none;
    }
    textarea:hover {
      cursor: default;
    }
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    user-select: none;
    cursor: default;
  }
}