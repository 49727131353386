.helper-text {
    position: absolute;
    padding: 15px;
    max-width: 547px;
    background: rgba(32, 32, 34, 0.5);;
    border-radius: 4px;
    color: white;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: justify;
    user-select: none;
    z-index: 6;
     
    &__next-step-button, &__finish-button {
        background: rgba(32, 32, 34, 0.4);
        z-index: 6;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 7.5px 15px;
        color: white;
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.08em;
    }
}