@import "../../scss/variables.scss";

.create-template-component {
  &__container {
    z-index: 2;
    position: relative;
  }

  &__button {
    background-color: map-get($purplePrimaryPalette, purplePrimary);
    color: #FFF;
    border-radius: 4px;
    font-size: 12px;
  }

  &__input {
    display: none;
    z-index: 3;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}