.wizard {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__content-disable-all-area {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
  }

  &__overlay {
    position: absolute;
    pointer-events: none;
    margin: auto;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    z-index: 5;
  }

  &__circle {
    pointer-events: stroke;
  }

  &__skip-tutorial-button {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 55px;
    right: calc(50% - 79px);
  }
}
