@import "../../scss/variables.scss";

#dblClickEditTextEditOn {
        display: block;
        border: none;
        font-size: 14px;
        outline-color: map-get($purplePrimaryPalette, purplePrimary);
        background-color: transparent;
        width: 95%;
        margin-top: 3.5px;
        height: 30px !important;
        line-height: 20px;
        font-weight: 400;
        margin-right: 0;
        resize: none;

       
}

#dblClickEditTextEditOff {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    height: 30px;
    padding: 5px 15px 5px 0px;
    &:hover {
        cursor: default;
    }
}