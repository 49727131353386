@import "../../scss/variables.scss";


#template-editor {
  flex-wrap: nowrap;
}

#edit-box {
  height: 100%;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 420px;
}

#canvas-with-control-panel-field {
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.template-editor-control-panel {
  position: absolute;
  width: 55px;
  display: flex !important;
  flex-direction: column !important;
  top: 12px;
  left: 12px;
  z-index: 99;
  .btn.btn-secondary:not(:disabled) {
    width: 44px;
    height: 44px;
    border-color: map-get($greyPalette, grey1);
    background-color: map-get($greyPalette, grey1);
  }
  .btn.btn-secondary:not(:disabled):hover {
    width: 44px;
    height: 44px;
    border-color: map-get($greyPalette, white);
    background-color: map-get($greyPalette, white);
  }
  .btn.btn.btn-secondary:active {
    box-shadow: none;
  }

  .btn.btn-secondary.btn-active {
    box-shadow: none;
    width: 44px;
    height: 44px;
    background-color: white;
    border: 1px solid map-get($purplePrimaryPalette, purplePrimary) !important;
  }
}

.template-editor-document-field {
  width: 100%;
  background-color: map-get($greyPalette, grey3);
}

.template-editor-create-area-form {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: map-get($greyPalette, white80);
  cursor: default;
  z-index: 99;
}

.template-editor-manage-actions-panel {
  z-index: 99;
  position: absolute;
  top: 12px;
  right: 10px;
}
