@import "../../../scss/variables.scss";

.toogle-view-component {
  &__view-list-icon {
    width: 16px;
    height: 16px;
    fill: map-get($greyPalette, grey5);

    &_selected {
      fill: map-get($purplePrimaryPalette, purplePrimary);
    }
  }
}
