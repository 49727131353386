@import "../../../scss/variables.scss";

#save-section-form {
  input,
  select {
    background-color: white;
  }
}

.save-area-form {
  margin: auto;
  max-width: 482px;
  width: 482px;

  &__header {
    background-color: map-get($purplePrimaryPalette, purplePrimary);
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    background-color: #FFF;
  }

  &__input {
    vertical-align: middle;
    padding: 0;
  }
}
