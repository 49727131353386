@import '../../scss/variables.scss';

.input-component {
  &__label {
    color: map-get($greyPalette, grey5);
    font-size: 12px;
  }

  &__input {
    margin-left: -8px;
    line-height: 28px;
    font-weight: 600;
    color: map-get($greyPalette, typeBlack);
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    background-color: map-get($greyPalette, white);
    width: calc(100% + 16px);

    &:focus {
      outline: 2px solid map-get($purplePrimaryPalette, purplePrimary);
      background-color: map-get($greyPalette, white);
    }
  }

  &__blocker {
    position: absolute;
    left: 0;
    top: 50px;
    bottom: 20px;
    right: 0;
  }
}

#base-input-component {
  background-color: map-get($greyPalette, white);
  border-bottom: 1px solid map-get($greyPalette, grey2);
  border-top: 1px solid map-get($greyPalette, grey2);
  padding-right: 25px;
  
  small {
    color: map-get($greyPalette, grey5);
  }

}

.icon-inside-input {
  position: absolute;
  right: 16px;
  top: 53px;
}